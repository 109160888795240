export function CloudProvider({ children }) {
    return <>{children}</>;
  }
  
  export function useCloud() {
    const generateToken = async () => {
      throw new Error("Not implemented");
    };
    const wsUrl = "";
  
    return { generateToken, wsUrl };
  }
  