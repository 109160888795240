import CallLogs from "./components/admin/UserCallLogs";
import Admin from "./pages/Admin";
import Home from "./pages/Home";
import { Routes, Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoutes from "./components/privateRoutes/PrivateRoutes";
import Signin from "./components/auth/Sigin";
import Contact from "./pages/Contact";
import LiveKit from "./components/microphoneStream/LiveKit";

function App() {
  return (
    <div className="App">
      <ToastContainer />

      <Routes>
        <Route index element={<Home />} />
        <Route path="/demo" element={<LiveKit />} />
        {/* <Route path="/demo" element={<MicrophoneStream />} /> */}
        <Route
          path="/call-logs"
          element={
            <PrivateRoutes>
              <Admin />
            </PrivateRoutes>
          }
        />
        <Route
          path="/call-logs/:id"
          element={
            <PrivateRoutes>
              <CallLogs />
            </PrivateRoutes>
          }
        />
        <Route path="/signin" element={<Signin />} />
        <Route path="/contact-us" element={<Contact />} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
