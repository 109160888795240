// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `   .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-container {
    background: #ffffff;
    padding: 20px 30px;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    text-align: center;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
  
  .modal-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #d32f2f; /* Red color to emphasize error */
    

    margin: 0 0 -20px 0;
     /* Remove extra space at the top and bottom */
  }
  
  .modal-subtitle {
    font-size: 1.25rem;
    font-weight: bold;
    color: #333333;
  }
  
  .modal-message {
    font-size: 1rem;
    color: #555555;
    line-height: 1.5;
    font-weight: 400;
    margin: 0 0 20px 0; /* Adds a gap before the Close button */
  }
  
  .modal-close-btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .modal-close-btn:hover {
    background-color: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/modal/Modal.css"],"names":[],"mappings":"GAAG;IACC,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;EACf;;EAEA;IACE,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;IAClB,UAAU;IACV,gBAAgB;IAChB,kBAAkB;IAClB,yCAAyC;EAC3C;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;IACjB,cAAc,EAAE,iCAAiC;;;IAGjD,mBAAmB;KAClB,6CAA6C;EAChD;;EAEA;IACE,kBAAkB;IAClB,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB,EAAE,uCAAuC;EAC7D;;EAEA;IACE,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["   .modal-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.5);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 1000;\n  }\n  \n  .modal-container {\n    background: #ffffff;\n    padding: 20px 30px;\n    border-radius: 8px;\n    width: 90%;\n    max-width: 400px;\n    text-align: center;\n    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);\n  }\n  \n  .modal-title {\n    font-size: 1.5rem;\n    font-weight: bold;\n    color: #d32f2f; /* Red color to emphasize error */\n    \n\n    margin: 0 0 -20px 0;\n     /* Remove extra space at the top and bottom */\n  }\n  \n  .modal-subtitle {\n    font-size: 1.25rem;\n    font-weight: bold;\n    color: #333333;\n  }\n  \n  .modal-message {\n    font-size: 1rem;\n    color: #555555;\n    line-height: 1.5;\n    font-weight: 400;\n    margin: 0 0 20px 0; /* Adds a gap before the Close button */\n  }\n  \n  .modal-close-btn {\n    padding: 10px 20px;\n    background-color: #007bff;\n    color: #ffffff;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    font-size: 1rem;\n    transition: background-color 0.3s ease;\n  }\n  \n  .modal-close-btn:hover {\n    background-color: #0056b3;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
