import React, { useState, useRef, useEffect } from "react";
import "../../style/call.css";
import HeaderLogo from "../../assets/footer-icon.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const Calls = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const getUserLogs = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/distinct-logs`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
          },
        }
      );
      console.log(
        "Feedback submitted successfully:",
        data.data.rows[0].is_processed
      );
      setData(data.data.rows);
    } catch (error) {
      console.error("There was an error submitting the feedback:", error);
    }
  };

  useEffect(() => {
    getUserLogs();
  }, []);

  return (
    <div className="admin-container">
      <div className="admin-inner-container section-spacing">
        <Link to="/">
          <div className="logo-content admin-logo">
            <img src={HeaderLogo} alt="logo" />
          </div>
        </Link>
        <table>
          <thead>
            <tr>
              <th>Registration Id</th>
              <th>Call Id</th>
              <th>Processed</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody id="stats">
            {data?.length ? (
              data?.map((data) => {
                return (
                  <tr key={data?.id}>
                    <td>{data?.user_id}</td>
                    <td>{data?.call_id}</td>
                    {data?.is_processed}
                    <td>{data?.is_processed === "true" ? "True" : "False"}</td>
                    <td
                      className="table-view-btn"
                      onClick={() => navigate(`/call-logs/${data?.user_id}`)}
                    >
                      View
                    </td>
                  </tr>
                );
              })
            ) : (
              <td colspan="4" className="no-data">
                No Data Found
              </td>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Calls;
