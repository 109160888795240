import { LoadingSVG } from "../button/LoadingSVG";
import { useConfig } from "../../hooks/useConfig";
import { TranscriptionTile } from "../../transcriptions/TranscriptionTile";
import {
  useConnectionState,
  useDataChannel,
  useLocalParticipant,
  useRoomInfo,
  useTracks,
  useVoiceAssistant,
} from "@livekit/components-react";
import "../microphoneStream/microphone.css";
import HeaderLogo from "../../assets/olleh_img.png";
import axios from "axios";
import { toast } from "react-toastify";
import callSuccessImg from "../../assets/call-start.png";
import callDangerImg from "../../assets/call-stop.png";
import ringingAudioFile from "../../assets/sounds/ringing.mp3";
import { ConnectionState, LocalParticipant, Track } from "livekit-client";
// import { QRCodeSVG } from "qrcode.react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button } from "../button/Button";
// import tailwindTheme from "../../lib/tailwindTheme.preval";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

const headerHeight = 56;

export default function Playground({ logo, themeColors, onConnect }) {
  const { config, setUserSettings } = useConfig();
  const { name } = useRoomInfo();
  const [transcripts, setTranscripts] = useState([]);
  const { localParticipant } = useLocalParticipant();
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(120);
  const [apiSuccess, setApiSuccess] = useState(false);
  // const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const [failedUrls, setFailedUrls] = useState([]);
  const [allFailed, setAllFailed] = useState(false); // Tracks if all URLs failed
  const navigate = useNavigate();


  const voiceAssistant = useVoiceAssistant();

  const roomState = useConnectionState();
  const tracks = useTracks();
  let fromEmail;
  const ringingAudio = useRef(new Audio(ringingAudioFile));

  const fetchUserData = async (userId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      const { data } = response;
      if (data.status === 200) {
  
        const failed = data.data?.processed_data?.filter((item) => !item.success);
        const succeeded = data.data?.processed_data?.some((item) => item.success);
  
        setFailedUrls(failed?.map((item) => item.url));
        setAllFailed(!succeeded); // Set `allFailed` if no successes
  
        // Show each failed URL in a separate toast
        // failed?.forEach((item) => {

        //   if (item.url?.includes("/uploads/")) {
        //     let fileName = item.url.split("/uploads/")[1]; // Extract file name after '/uploads/'

        //     // Remove the timestamp from the file name (assuming it's the first part separated by '-')
        //     fileName = fileName.replace(/^\d+-/, "");
        //     toast.error(`Unable to scrape data for: ${fileName}`);
        //   }else{
        //     toast.error(`Unable to scrape data for: ${item.url}`);
 
        //   }
        // });
  
        return { userData: data.data, succeeded };
      } else {
        setError(data.message);
      }
    } catch (err) {
      setError("Failed to fetch user data");
      console.error("Error fetching user data:", err);
    }
  };
  
  useEffect(() => {
    const getData = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");
      const decodedToken = jwtDecode(token);
      const userId = decodedToken?.user?.id;
  
  
      const { userData, succeeded } = await fetchUserData(userId);
  
      if (userData?.is_processed === false) {
        const pollApi = async () => {
          setApiSuccess(true);
          try {
            const response = await axios.get(
              `${process.env.REACT_APP_API_URL}/user/vector-data`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
  
            if (response?.data?.status === 200) {
              const { userData, succeeded } = await fetchUserData(userId);
  
              if (succeeded) {
                toast.success("Agent is ready!");
              }else{
                navigate('/',{
                  state: {
                    showModal: true,
                  },
                });
              }
            }
          } catch (error) {
            toast.error("Failed to fetch agent readiness status.");
          }
          setApiSuccess(false);
        };
  
        pollApi();
      }
    };
  
    getData();
  }, []);
  
  
  const formattedTime = useMemo(() => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  }, [timer]);

  useEffect(() => {
    if (roomState === ConnectionState.Connected) {
      localParticipant.setMicrophoneEnabled(config.settings.inputs.mic);
    }
  }, [config, localParticipant, roomState]);

  const localTracks = tracks.filter(
    ({ participant }) => participant instanceof LocalParticipant
  );

  useEffect(() => {
    if (timer > 0 && apiSuccess) {
      const interval = setInterval(() => setTimer((prev) => prev - 1), 1000);
      return () => clearInterval(interval);
    }
  }, [timer, apiSuccess]);

  // useEffect(() => {
  //   if (
  //     (roomState === ConnectionState.Connected && voiceAssistant.agent) ||
  //     roomState === ConnectionState.Disconnected
  //   ) {
  //     setLoading(false);
  //   }
  // }, [roomState]);

  useEffect(() => {
    if (roomState === ConnectionState.Connecting) {
      // Play the ringing sound when the call is connecting
      ringingAudio.current.play().catch((error) => {
        console.error("Error playing audio:", error);
      });
    } else if (
      (roomState === ConnectionState.Connected && voiceAssistant.agent) ||
      roomState === ConnectionState.Disconnected
    ) {
      // Stop the ringing sound when the call is connected or disconnected
      ringingAudio.current.pause();
      ringingAudio.current.currentTime = 0;
      setLoading(false);
    }
  }, [roomState]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      // Ensure that the call is ended properly when the browser/tab is closed
      if (roomState === ConnectionState.Connected) {
        // Handle any necessary cleanup or end the call here
        onConnect(true); // Passing 'true' to disconnect
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [roomState, onConnect]);

  const onDataReceived = useCallback(
    (msg) => {
      if (msg.topic === "transcription") {
        const decoded = JSON.parse(
          new TextDecoder("utf-8").decode(msg.payload)
        );
        let timestamp = new Date().getTime();
        if ("timestamp" in decoded && decoded.timestamp > 0) {
          timestamp = decoded.timestamp;
        }
        setTranscripts([
          ...transcripts,
          {
            name: "You",
            message: decoded.text,
            timestamp: timestamp,
            isSelf: true,
          },
        ]);
      }
    },
    [transcripts]
  );

  useDataChannel(onDataReceived);

  const chatTileContent = useMemo(() => {
    if (voiceAssistant.audioTrack) {
      return (
        <TranscriptionTile
          agentAudioTrack={voiceAssistant.audioTrack}
          accentColor={config.settings.theme_color}
        />
      );
    }
    return <></>;
  }, [config.settings.theme_color, voiceAssistant.audioTrack]);

  let mobileTabs = [];

  if (config.settings.chat) {
    mobileTabs.push({
      title: "Chat",
      content: chatTileContent,
    });
  }

  const handleStartCall = () => {
    if (allFailed) {
      toast.error("Please resolve the issues with the URLs before starting the call.");
      return;
    }
    setLoading(true);
    onConnect(roomState === ConnectionState.Disconnected);
  };

  return (
    <>
      <div
        className={`flex flex-col w-full`}
        style={{ height: `calc(100% - ${headerHeight}px)` }}
      >
        <div className="microphone-container">
          <div className="microphone-inner-container section-spacing">
            <div className="logo-content">
              <img src={HeaderLogo} alt="logo" />
            </div>
            <div className="audio-btns">
              {apiSuccess ? (
                <div className="timer-container flex justify-center flex-col pt-8">
                  <h4 className="text-[#d9e2ea]">
                    Agent Is Processing Your Data. Please Stay With Us!
                  </h4>
                  <span className="timer text-center text-[#d9e2ea] text-5xl pt-4">
                    {formattedTime}
                  </span>
                </div>
              ) : (
                <>
                  <audio id="assistant_audio"></audio>
                  <div
                    disabled={
                      loading || roomState === ConnectionState.Connecting
                    }
                    onClick={() => {
                      if (!allFailed) {
                        setLoading(true);
                        onConnect(roomState === ConnectionState.Disconnected);
                      }
                    }}
                    className="call-img"
                  >
                    {roomState === ConnectionState.Connected &&
                    voiceAssistant.agent ? (
                      <img src={callDangerImg} alt="callDangerImage" />
                    ) : (
                      <img src={callSuccessImg} alt="callSuccessImage" />
                    )}
                  </div>
                  <Button
                    disabled={
                      loading ||
                      roomState === ConnectionState.Connecting ||
                      allFailed
                    }
                    // onClick={() => {
                    //   setLoading(true);
                    //   onConnect(roomState === ConnectionState.Disconnected);
                    // }}
                    onClick={handleStartCall}
                    className={
                      roomState === ConnectionState.Connected
                        ? "stop-call call-btn-text"
                        : "start-call call-btn-text"
                    }
                  >
                    {roomState === ConnectionState.Connecting || loading ? (
                      <LoadingSVG />
                    ) : roomState === ConnectionState.Connected ? (
                      "End Call"
                    ) : (
                      "Start Call"
                    )}
                  </Button>
                </>
              )}
              {chatTileContent}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
