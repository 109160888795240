import { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HelmetProvider } from "react-helmet-async";
import PrivateRoutes from "./components/privateRoutes/PrivateRoutes";
import Loader from "./components/common/loader/Loader";

// 🔹 Lazy Load All Components
const Home = lazy(() => import("./pages/Home"));
const LiveKit = lazy(() => import("./components/microphoneStream/LiveKit"));
const Admin = lazy(() => import("./pages/Admin"));
const CallLogs = lazy(() => import("./components/admin/UserCallLogs"));
const Signin = lazy(() => import("./components/auth/Sigin"));
const Contact = lazy(() => import("./pages/Contact"));
const BlogsList = lazy(() => import("./pages/BlogsList"));
const ViewBlog = lazy(() => import("./pages/ViewBlog"));
const NotFound = lazy(() => import("./pages/NotFound"));

function App() {
  return (
    <HelmetProvider>
      <div className="App">
        <ToastContainer />

        <Suspense fallback={<Loader />}>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/demo" element={<LiveKit />} />
            {/* <Route path="/demo" element={<MicrophoneStream />} /> */}
            <Route
              path="/call-logs"
              element={
                <PrivateRoutes>
                  <Admin />
                </PrivateRoutes>
              }
            />
            <Route
              path="/call-logs/:id"
              element={
                <PrivateRoutes>
                  <CallLogs />
                </PrivateRoutes>
              }
            />
            <Route path="/blog/:slug/:id" element={<ViewBlog />} />
            <Route path="/blogs" element={<BlogsList />} />
            <Route path="/signin" element={<Signin />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
        <ToastContainer />
      </div>
    </HelmetProvider>
  );
}

export default App;
