import React from "react";
import "../../../style/home.css";

const CompanyAgent = ({ scrollToSection }) => {
  return (
    <>
      <div className="company-agent">
        <div className="compay-agent-wrapper section-spacing">
          <h3>
            <b>Demo Time!</b> Talk to our AI model during the demo. It's like
            speed dating, but for virtual assistants!
            <br /> And the best part? It's{" "}
            <b className="company-btn">personalized</b> just for you!
          </h3>
          <button className="btn-secondary" onClick={scrollToSection}>
            Book your demo
          </button>
        </div>
      </div>
    </>
  );
};

export default CompanyAgent;
