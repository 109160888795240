import React, { useRef } from "react";
import Header from "../components/common/header/Header";
import Introduction from "../components/home/introduction/Introduction";
import Waitlist from "../components/home/waitlist/Waitlist";
import CompanyAgent from "../components/home/companyAgent/CompanyAgent";
import CompanyWork from "../components/home/companyWork/CompanyWork";
import Footer from "../components/common/footer/Footer";
import { useLocation } from "react-router-dom";


const Home = () => {
  const sectionThreeRef = useRef(null);
  const location = useLocation();

  const scrollToSection = () => {
    sectionThreeRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      <Header scrollToSection={scrollToSection} />
      <Introduction scrollToSection={scrollToSection} />
      <Waitlist ref={sectionThreeRef} location={location.state}/>
      <CompanyAgent scrollToSection={scrollToSection} />
      <CompanyWork scrollToSection={scrollToSection} />
      <Footer />
    </>
  );
};

export default Home;
