import React, { useState } from "react";
import "../../style/home.css";
import waitlistIcon from "../../assets/waitlist-icon.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ContactForm = () => {
  const [userValue, setUserValue] = useState({
    name: "",
    email: "",
    message: "",
  });
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState({});
  const [isFormFocused, setIsFormFocused] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserValue({
      ...userValue,
      [name]: value,
    });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
  };

  const validateField = (name, value) => {
    let errors = { ...formErrors };

    switch (name) {
      case "name":
        errors.name = value ? "" : "Please enter your name";
        break;
      case "email":
        const emailPattern =
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        errors.email = value
          ? emailPattern.test(value)
            ? ""
            : "Please enter a valid email"
          : "Please enter your email";
        break;
      case "message":
        errors.message = value ? "" : "Please enter your name";
        break;
      default:
        break;
    }

    setFormErrors(errors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fields = Object.keys(userValue);
    let valid = true;
    let errors = {};

    fields.forEach((field) => {
      validateField(field, userValue[field]);
      if (!userValue[field]) {
        valid = false;
        errors[field] = `Please enter your ${field
          .replace(/([A-Z])/g, " $1")
          .toLowerCase()}`;
      }
    });

    setFormErrors(errors);
    setIsFormFocused(true);
    console.log({ userValue });

    if (valid) {
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_URL}/user/contact-email`,
          userValue
        );
        if (data?.status === 200) {
          toast.success("Form submited Successfully!");
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
    setUserValue({
      name: "",
      email: "",
      message: "",
    });
  };

  return (
    <div className="auth-main-container waitlist-main-continer waitlist-container">
      <div className="auth-wrapper waitlist-wrapper section-spacing">
        <div className="waitlist-wrapper-content">
          <div className="waitlist-header">
            <div className="icon-container">
              <img src={waitlistIcon} alt="Robot Icon" className="robot-icon" />
            </div>
            <h2>Contact us</h2>
          </div>
          <form
            className="waitlist-main-form waitlist-form"
            onSubmit={handleSubmit}
          >
            <div className="waitlist-form-content waitlist-form-wrapper">
              <div>
                <input
                  type="name"
                  placeholder="Name"
                  value={userValue.name}
                  name="name"
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                />
                {formErrors.name && (
                  <span className="error-message">{formErrors.name}</span>
                )}
              </div>
              <div>
                <input
                  type="email"
                  placeholder="Email"
                  value={userValue.email}
                  name="email"
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                />
                {formErrors.email && (
                  <span className="error-message">{formErrors.email}</span>
                )}
              </div>
              <div>
                <textarea
                  placeholder="Message"
                  value={userValue.message}
                  name="message"
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                />
                {formErrors.message && (
                  <span className="error-message contact-error">
                    {formErrors.message}
                  </span>
                )}
              </div>

              <button
                type="submit"
                className={`contact-us-btn btn-primary ${
                  isFormFocused ? "form-focused" : ""
                }`}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
