import "../../../style/viewBlog.css"

const Loader = () => {
    return (
        <div className="loading-container">
            <div className="spinner"></div>
            <p className="loading-text">Loading ...</p>
        </div>
    );
};

export default Loader;