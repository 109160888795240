// import { ChatTile } from "../components/chat/ChatTile";
// import {
//   TrackReferenceOrPlaceholder,
//   useChat,
//   useLocalParticipant,
//   useTrackTranscription,
// } from "@livekit/components-react";
// import { useEffect, useState } from "react";
// import {
//   LocalParticipant,
//   Participant,
//   Track,
//   TranscriptionSegment,
// } from "livekit-client";

// export function TranscriptionTile({ agentAudioTrack, accentColor }) {
//   const agentMessages = useTrackTranscription(agentAudioTrack);
//   const localParticipant = useLocalParticipant();
//   const localMessages = useTrackTranscription({
//     publication: localParticipant.microphoneTrack,
//     source: Track.Source.Microphone,
//     participant: localParticipant.localParticipant,
//   });

//   const [transcripts, setTranscripts] = useState(new Map());
//   const [messages, setMessages] = useState([]);
//   const { chatMessages, send: sendChat } = useChat();

//   // store transcripts
//   useEffect(() => {
//     agentMessages.segments.forEach((s) =>
//       transcripts.set(
//         s.id,
//         segmentToChatMessage(
//           s,
//           transcripts.get(s.id),
//           agentAudioTrack.participant
//         )
//       )
//     );
//     localMessages.segments.forEach((s) =>
//       transcripts.set(
//         s.id,
//         segmentToChatMessage(
//           s,
//           transcripts.get(s.id),
//           localParticipant.localParticipant
//         )
//       )
//     );

//     const allMessages = Array.from(transcripts.values());
//     for (const msg of chatMessages) {
//       const isAgent =
//         msg.from?.identity === agentAudioTrack.participant?.identity;
//       const isSelf =
//         msg.from?.identity === localParticipant.localParticipant.identity;
//       let name = msg.from?.name;
//       if (!name) {
//         if (isAgent) {
//           name = "JOHN";
//         } else if (isSelf) {
//           name = "YOU";
//         } else {
//           name = "Unknown";
//         }
//       }
//       allMessages.push({
//         name,
//         message: msg.message,
//         timestamp: msg.timestamp,
//         isSelf: isSelf,
//       });
//     }
//     allMessages.sort((a, b) => a.timestamp - b.timestamp);
//     setMessages(allMessages);
//   }, [
//     transcripts,
//     chatMessages,
//     localParticipant.localParticipant,
//     agentAudioTrack.participant,
//     agentMessages.segments,
//     localMessages.segments,
//   ]);

//   return <ChatTile messages={messages} accentColor={accentColor} onSend={sendChat} />;
// }

// function segmentToChatMessage(s, existingMessage, participant) {
//   const msg = {
//     message: s.final ? s.text : `${s.text} ...`,
//     name: participant instanceof LocalParticipant ? "YOU" : "JOHN",
//     isSelf: participant instanceof LocalParticipant,
//     timestamp: existingMessage?.timestamp ?? Date.now(),
//   };
//   return msg;
// }



// import { ChatTile } from "../components/chat/ChatTile";
// import {
//   useLocalParticipant,
//   useTrackTranscription,
// } from "@livekit/components-react";
// import { useEffect, useState } from "react";
// import { Track } from "livekit-client";

// export function TranscriptionTile({ agentAudioTrack, accentColor }) {
//   const agentMessages = useTrackTranscription(agentAudioTrack);
//   const localParticipant = useLocalParticipant();
//   const localMessages = useTrackTranscription({
//     publication: localParticipant.microphoneTrack,
//     source: Track.Source.Microphone,
//     participant: localParticipant.localParticipant,
//   });

//   const [latestAgentMessage, setLatestAgentMessage] = useState(null);
//   const [latestUserMessage, setLatestUserMessage] = useState(null);

//   useEffect(() => {
//     const latestAgentSegment = agentMessages.segments.slice(-1)[0]; // Get the latest agent segment
//     const latestUserSegment = localMessages.segments.slice(-1)[0]; // Get the latest user segment

//     if (latestAgentSegment) {
//       setLatestAgentMessage(
//         segmentToChatMessage(latestAgentSegment, "JOHN", false)
//       );
//     }

//     if (latestUserSegment) {
//       setLatestUserMessage(
//         segmentToChatMessage(latestUserSegment, "YOU", true)
//       );
//     }
//   }, [agentMessages.segments, localMessages.segments]);

//   return (
//     <ChatTile
//       messages={[latestAgentMessage, latestUserMessage].filter(Boolean)} // Show only the latest messages
//       accentColor={accentColor}
//       onSend={() => {}}
//     />
//   );
// }

// function segmentToChatMessage(segment, name, isSelf) {
//   return {
//     message: segment.final ? segment.text : `${segment.text} ...`,
//     name,
//     isSelf,
//     timestamp: segment.timestamp,
//   };
// }

import { ChatTile } from "../components/chat/ChatTile";
import {
  useLocalParticipant,
  useTrackTranscription,
} from "@livekit/components-react";
import { useEffect, useState } from "react";
import { Track } from "livekit-client";

export function TranscriptionTile({ agentAudioTrack, accentColor }) {
  const agentMessages = useTrackTranscription(agentAudioTrack);
  const localParticipant = useLocalParticipant();
  const localMessages = useTrackTranscription({
    publication: localParticipant.microphoneTrack,
    source: Track.Source.Microphone,
    participant: localParticipant.localParticipant,
  });

  const [currentMessage, setCurrentMessage] = useState(null);

  useEffect(() => {
    const latestAgentSegment = agentMessages.segments.slice(-1)[0];
    const latestUserSegment = localMessages.segments.slice(-1)[0]


    if (latestAgentSegment && latestUserSegment) {
      
      if (latestAgentSegment.firstReceivedTime > latestUserSegment.firstReceivedTime) {
        setCurrentMessage(
          segmentToChatMessage(latestAgentSegment, "JOHN", false)
        );
      } else {
        setCurrentMessage(
          segmentToChatMessage(latestUserSegment, "YOU", true)
        );
      }
    } else if (latestAgentSegment) {
      setCurrentMessage(segmentToChatMessage(latestAgentSegment, "JOHN", false));
    } else if (latestUserSegment) {
      setCurrentMessage(segmentToChatMessage(latestUserSegment, "YOU", true));
    }
  }, [agentMessages.segments, localMessages.segments]);

  return (
    <ChatTile
      messages={currentMessage ? [currentMessage] : []}
      accentColor={accentColor}
      onSend={() => {}}
    />
  );
}

function segmentToChatMessage(segment, name, isSelf) {
  return {
    message: segment.final ? segment.text : `${segment.text} ...`,
    name,
    isSelf,
    timestamp: segment.firstReceivedTime
  };
}


