import React from "react";
import AdminData from "../components/admin/CallLogs";

const Admin = () => {
  return (
    <div>
      <AdminData />
    </div>
  );
};

export default Admin;
