import { Navigate, useLocation } from "react-router-dom";

const PrivateRoutes = ({ children }) => {
  const isAuthenticated = JSON.parse(localStorage.getItem("token"));
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  return children;
};

export default PrivateRoutes;
