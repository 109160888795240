import React, { useEffect, useState } from "react";
import workImage from "../../../assets/how-it-work-info.png";
import workIcon from "../../../assets/work-icon.png";
import StepIcon_1 from "../../../assets/step-1-icon.png";
import StepIcon_2 from "../../../assets/step-2-icon.png";
import StepIcon_3 from "../../../assets/step-3-icon.png";
import StepIcon_4 from "../../../assets/step-4-icon.png";
import StepIcon_5 from "../../../assets/step-5-icon.png";
import StepIcon_6 from "../../../assets/step-6-icon.png";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

const CompanyWork = ({ scrollToSection }) => {
  const steps = [
    {
      id: 1,
      title: "STEP 01:",
      description: "You Give Information about your company or product",
      icon: StepIcon_1,
    },
    {
      id: 2,
      title: "STEP 02:",
      description: "We Analyze the Information",
      icon: StepIcon_2,
    },
    {
      id: 3,
      title: "STEP 03:",
      description: "We Provide Feedback",
      icon: StepIcon_3,
    },
    {
      id: 4,
      title: "STEP 04:",
      description: "You Implement Our Suggestions",
      icon: StepIcon_4,
    },
    {
      id: 5,
      title: "STEP 05:",
      description: "Final Review and Approval",
      icon: StepIcon_5,
    },
    {
      id: 6,
      title: "STEP 06:",
      description: "Final Review and Approval",
      icon: StepIcon_6,
    },
  ];
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentStep((prevStep) => (prevStep + 1) % steps.length);
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  const handleIndicatorClick = (index) => {
    setCurrentStep(index);
  };

  const handleArrowClick = (direction) => {
    if (direction === "left") {
      setCurrentStep(
        (prevStep) => (prevStep - 1 + steps.length) % steps.length
      );
    } else {
      setCurrentStep((prevStep) => (prevStep + 1) % steps.length);
    }
  };

  return (
    <>
      <section className="company-work">
        <img src={workIcon} alt="workIcon" className="work-icon" />
        <div className="company-work-wrapper section-spacing">
          <h2>How It Works</h2>
          <img src={workImage} alt="workImage" />
          <button className="btn-primary" onClick={scrollToSection}>
            Book your demo
          </button>
        </div>

        {/* ======================================= Slider on Mobile ==================================== */}

        <div className="step-slider section-spacing">
          <div className="step-content">
            <div className="step-icon">
              <img src={steps[currentStep].icon} alt="Step Icon" />
            </div>
            <h2>{steps[currentStep].title}</h2>
            <p>{steps[currentStep].description}</p>
          </div>
          <div className="step-arrows">
            <AiOutlineArrowLeft
              className="arrow-left"
              onClick={() => handleArrowClick("left")}
            />
            <AiOutlineArrowRight
              className="arrow-right"
              onClick={() => handleArrowClick("right")}
            />
          </div>
          <div className="step-indicators">
            {steps.map((step, index) => (
              <span
                key={step.id}
                className={index === currentStep ? "active" : ""}
                onClick={() => handleIndicatorClick(index)}
              ></span>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default CompanyWork;
