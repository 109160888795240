import React from "react";
import footerLogo from "../../../assets/footer-icon.png";
import "../../../style/footer.css";
import { LinkedinIcon, FacebookIcon } from "react-share";
import { FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <section className="olleh-footer">
        <div className="olleh-footer-wrapper section-spacing">
          <Link to="#">
            <img src={footerLogo} alt="footerLogo" className="footer-logo inline" />
          </Link>
          <div className="olleh-footer-content">
            <div>
              <p>© {currentYear} Olleh.ai. All Right Reserved </p>
            </div>
            <div>
              <Link to="#" className="privacy-policy">
                Privacy Policy
              </Link>
              <Link to="#"> Support Center </Link>
            </div>
          </div>

          <div className="olleh-share-icons">
          <a href="https://www.facebook.com/profile.php?id=61567718817063&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
                <FacebookIcon size={32} round={true} />
            </a>
            <a href="https://www.instagram.com/ollehai" target="_blank" rel="noopener noreferrer">
                <FaInstagram size={32} style={{ color: '#C13584' }} />
            </a>
            <a href="https://www.linkedin.com/company/olleh-ai/" target="_blank" rel="noopener noreferrer">
                <LinkedinIcon size={32} round={true} />
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
