import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "../../style/call.css";
import HeaderLogo from "../../assets/footer-icon.png";

const CallLogs = () => {
  const [data, setData] = useState([]);
  const { id: userId } = useParams();
  const getUserLogs = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/logs/?user_id=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
          },
        }
      );
      setData(data?.data?.rows);
    } catch (error) {
      console.error("There was an error submitting the feedback:", error);
    }
  };

  useEffect(() => {
    getUserLogs();
  }, []);

  function formatDateTime(date) {
    const dateString = date;
    const utcDateTime = new Date(dateString);
    return utcDateTime.toLocaleString();
  }
  return (
    <div className="admin-container">
      <div className="admin-inner-container section-spacing">
        <div className="logo-content admin-logo">
          <img src={HeaderLogo} alt="logo" />
        </div>

        <table>
          <thead>
            <tr>
              <th>Frontend</th>
              <th>Backend</th>
              <th>STT Time</th>
              <th>RAG Time</th>
              <th>TTS Time</th>
              <th>Received Time</th>
              <th>Size</th>
              <th>Text</th>
              <th>RAG</th>
              <th>User Speaking</th>
              <th>Registration Id</th>
              <th>Call Id</th>
            </tr>
          </thead>
          <tbody id="stats">
            {data.length ? (
              data?.map((log, index) => (
                <tr key={index}>
                  <td>{formatDateTime(log?.frontend_time)}</td>
                  <td>{formatDateTime(log?.backend_time)}</td>
                  <td>{log?.time_for_stt}</td>
                  <td>{log?.time_for_rag}</td>
                  <td>{log?.time_for_tts}</td>
                  <td>{formatDateTime(log?.get_formatted_utc_time)}</td>
                  <td>{log?.length}</td>
                  <td>{log?.text}</td>
                  <td>{log?.rag}</td>
                  <td>{log?.is_user_speaking === "true" ? "True" : "False"}</td>
                  <td>{userId}</td>
                  <td>{log?.call_id}</td>
                </tr>
              ))
            ) : (
              <td colspan="12" className="no-data">
                No Data Found
              </td>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CallLogs;
