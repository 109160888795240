import { useCloud } from "../cloud/useCloud";
import React, { createContext, useState, useCallback, useContext } from "react";
import { useConfig } from "./useConfig";
import { useToast } from "../components/toast/ToasterProvider";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";


const ConnectionContext = createContext(undefined);

export const ConnectionProvider = ({ children }) => {
  const { generateToken, wsUrl: cloudWSUrl } = useCloud();
  const { setToastMessage } = useToast();
  const { config } = useConfig();
  const [connectionDetails, setConnectionDetails] = useState({
    wsUrl: "",
    token: "",
    shouldConnect: false,
    mode: "manual",
  });

  const navigate = useNavigate()

  const connect = useCallback(
    async (mode) => {
      let token = "";
      let url = "";
      // if (mode === "cloud") {
      //   try {
      //     token = await generateToken();
      //   } catch (error) {
      //     setToastMessage({
      //       type: "error",
      //       message:
      //         "Failed to generate token, you may need to increase your role in this LiveKit Cloud project.",
      //     });
      //   }
      //   url = cloudWSUrl;
      // } else 
      if (mode === "env") {
        if (!process.env.REACT_APP_LIVEKIT_URL) {
          throw new Error("REACT_APP_LIVEKIT_URL is not set");
        }
        url = process.env.REACT_APP_LIVEKIT_URL;

        const urlParams = new URLSearchParams(window.location.search);
        token = urlParams.get("token") || "";
        if (!token) {
          toast.error('Authentication failed.');
          setTimeout(() => {
            navigate('/') 
          }, 2000);
        }

        const response = await fetch(
          "https://pyapi.olleh.ai/register_user_session",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ token }),
          }
        );

        const responseToken = await response.json();
        // console.log('responseToken', responseToken)
        // console.log('responseToken?.status', responseToken?.status)
        if (responseToken?.status === false) {
          // setToastMessage({
          //   type: "error",
          //   message: "Authentication failed.",
          // });
          toast.error('Authentication failed. Please try again!');
          // window.location.reload()
          setTimeout(() => {
            navigate('/')
          }, 2000);
        }

        token = responseToken?.result?.lt_token;
      } else {
        token = config.settings.token;
        url = config.settings.ws_url;
      }
      setConnectionDetails({ wsUrl: url, token, shouldConnect: true, mode });
    },
    [cloudWSUrl, config.settings.token, config.settings.ws_url, generateToken, setToastMessage]
  );

  const disconnect = useCallback(() => {
    setConnectionDetails((prev) => ({ ...prev, shouldConnect: false }));
  }, []);

  return (
    <ConnectionContext.Provider
      value={{
        wsUrl: connectionDetails.wsUrl,
        token: connectionDetails.token,
        shouldConnect: connectionDetails.shouldConnect,
        mode: connectionDetails.mode,
        connect,
        disconnect,
      }}
    >
      {children}
    </ConnectionContext.Provider>
  );
};

export const useConnection = () => {
  const context = useContext(ConnectionContext);
  if (context === undefined) {
    throw new Error("useConnection must be used within a ConnectionProvider");
  }
  return context;
};
