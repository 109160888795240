// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .olleh-header{
    background-color: #e0e0e0;
} */
.olleh-header-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.olleh-header-wrapper.section-spacing{
    padding: 2rem 0;
}

.olleh-logo img{
    max-width: 260px;
}
.olleh-menu ul{
display: flex;
gap: 1.5rem;
}
.olleh-menu ul li{
    list-style-type: none;
}`, "",{"version":3,"sources":["webpack://./src/style/header.css"],"names":[],"mappings":"AAAA;;GAEG;AACH;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;AACA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;AACA;AACA,aAAa;AACb,WAAW;AACX;AACA;IACI,qBAAqB;AACzB","sourcesContent":["/* .olleh-header{\n    background-color: #e0e0e0;\n} */\n.olleh-header-wrapper{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n.olleh-header-wrapper.section-spacing{\n    padding: 2rem 0;\n}\n\n.olleh-logo img{\n    max-width: 260px;\n}\n.olleh-menu ul{\ndisplay: flex;\ngap: 1.5rem;\n}\n.olleh-menu ul li{\n    list-style-type: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
