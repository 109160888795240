import { getCookie, setCookie } from "cookies-next";
import jsYaml from "js-yaml";
// import { useRouter } from "next/navigation";
import { useNavigate, useLocation } from "react-router-dom";
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from "react";

// Default configuration if NEXT_PUBLIC_APP_CONFIG is not set
const defaultConfig = {
  title: "LiveKit Agents Playground",
  description: "A playground for testing LiveKit Agents",
  video_fit: "cover",
  settings: {
    editable: true,
    theme_color: "cyan",
    chat: true,
    inputs: {
      camera: true,
      mic: true,
    },
    outputs: {
      audio: true,
      video: true,
    },
    ws_url: "",
    token: "",
  },
  show_qr: false,
};

const useAppConfig = () => {
  return useMemo(() => {
    if (process.env.NEXT_PUBLIC_APP_CONFIG) {
      try {
        const parsedConfig = jsYaml.load(
          process.env.NEXT_PUBLIC_APP_CONFIG
        );
        if (!parsedConfig.settings) {
          parsedConfig.settings = defaultConfig.settings;
        }
        if (parsedConfig.settings.editable === undefined) {
          parsedConfig.settings.editable = true;
        }
        return parsedConfig;
      } catch (e) {
        console.error("Error parsing app config:", e);
      }
    }
    return defaultConfig;
  }, []);
};

const ConfigContext = createContext(undefined);

export const ConfigProvider = ({ children }) => {
  const appConfig = useAppConfig();
  // const router = useRouter();
  const navigate = useNavigate(); // Use navigate for navigation
  const location = useLocation();
  const [localColorOverride, setLocalColorOverride] = useState(null);

  const getSettingsFromUrl = useCallback(() => {
    if (typeof window === "undefined" || !location.hash) {
      return null;
    }
    const appConfigFromSettings = appConfig;
    if (!appConfigFromSettings.settings.editable) {
      return null;
    }
    const params = new URLSearchParams(location.hash.replace("#", ""));
    return {
      editable: true,
      chat: params.get("chat") === "1",
      theme_color: params.get("theme_color"),
      inputs: {
        camera: params.get("cam") === "1",
        mic: params.get("mic") === "1",
      },
      outputs: {
        audio: params.get("audio") === "1",
        video: params.get("video") === "1",
      },
      ws_url: "",
      token: "",
    };
  }, [appConfig, location]);

  const getSettingsFromCookies = useCallback(() => {
    const appConfigFromSettings = appConfig;
    if (!appConfigFromSettings.settings.editable) {
      return null;
    }
    const jsonSettings = getCookie("lk_settings");
    if (!jsonSettings) {
      return null;
    }
    return JSON.parse(jsonSettings);
  }, [appConfig]);

  const setUrlSettings = useCallback(
    (settings) => {
      const obj = new URLSearchParams({
        cam: boolToString(settings.inputs.camera),
        mic: boolToString(settings.inputs.mic),
        video: boolToString(settings.outputs.video),
        audio: boolToString(settings.outputs.audio),
        chat: boolToString(settings.chat),
        theme_color: settings.theme_color || "cyan",
      });
      navigate("/#" + obj.toString());
      // router.replace("/#" + obj.toString());
    },
    [navigate]
  );

  const setCookieSettings = useCallback((settings) => {
    const json = JSON.stringify(settings);
    setCookie("lk_settings", json);
  }, []);

  const getConfig = useCallback(() => {
    const appConfigFromSettings = appConfig;

    if (!appConfigFromSettings.settings.editable) {
      if (localColorOverride) {
        appConfigFromSettings.settings.theme_color = localColorOverride;
      }
      return appConfigFromSettings;
    }
    const cookieSettings = getSettingsFromCookies();
    const urlSettings = getSettingsFromUrl();
    if (!cookieSettings) {
      if (urlSettings) {
        setCookieSettings(urlSettings);
      }
    }
    if (!urlSettings) {
      if (cookieSettings) {
        setUrlSettings(cookieSettings);
      }
    }
    const newCookieSettings = getSettingsFromCookies();
    if (!newCookieSettings) {
      return appConfigFromSettings;
    }
    appConfigFromSettings.settings = newCookieSettings;
    return { ...appConfigFromSettings };
  }, [
    appConfig,
    getSettingsFromCookies,
    getSettingsFromUrl,
    localColorOverride,
    setCookieSettings,
    setUrlSettings,
  ]);

  const setUserSettings = useCallback(
    (settings) => {
      const appConfigFromSettings = appConfig;
      if (!appConfigFromSettings.settings.editable) {
        setLocalColorOverride(settings.theme_color);
        return;
      }
      setUrlSettings(settings);
      setCookieSettings(settings);
      _setConfig((prev) => ({
        ...prev,
        settings,
      }));
    },
    [appConfig, setCookieSettings, setUrlSettings]
  );

  const [config, _setConfig] = useState(getConfig());

  useEffect(() => {
    _setConfig(getConfig());
  }, [getConfig]);

  return (
    <ConfigContext.Provider value={{ config, setUserSettings }}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = () => {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error("useConfig must be used within a ConfigProvider");
  }
  return context;
};

const boolToString = (b) => (b ? "1" : "0");
