// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-container{
    background-color: #11132B;
    min-height: 100vh;
}
.admin-inner-container{
    overflow: auto;
}
th{
    text-align: center;
    padding: 0.8rem;
}
td{
    color: var(--secondary-color);
    text-align: center;
    padding: 0.8rem;
}
thead{
    background-color: #11132B;
}
.admin-logo{
    padding-bottom: 3rem;
}
.table-view-btn{
    /* background-color: var(--secondary-color);
    color: var(--primary-color); */
    text-decoration: underline;
    cursor: pointer;
}
.no-data{
    padding: 4rem 0;
    color: var(--secondary-color);
    text-align: center;
    border: 0;
    font-size: 28px;
}`, "",{"version":3,"sources":["webpack://./src/style/call.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,iBAAiB;AACrB;AACA;IACI,cAAc;AAClB;AACA;IACI,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,6BAA6B;IAC7B,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,oBAAoB;AACxB;AACA;IACI;kCAC8B;IAC9B,0BAA0B;IAC1B,eAAe;AACnB;AACA;IACI,eAAe;IACf,6BAA6B;IAC7B,kBAAkB;IAClB,SAAS;IACT,eAAe;AACnB","sourcesContent":[".admin-container{\n    background-color: #11132B;\n    min-height: 100vh;\n}\n.admin-inner-container{\n    overflow: auto;\n}\nth{\n    text-align: center;\n    padding: 0.8rem;\n}\ntd{\n    color: var(--secondary-color);\n    text-align: center;\n    padding: 0.8rem;\n}\nthead{\n    background-color: #11132B;\n}\n.admin-logo{\n    padding-bottom: 3rem;\n}\n.table-view-btn{\n    /* background-color: var(--secondary-color);\n    color: var(--primary-color); */\n    text-decoration: underline;\n    cursor: pointer;\n}\n.no-data{\n    padding: 4rem 0;\n    color: var(--secondary-color);\n    text-align: center;\n    border: 0;\n    font-size: 28px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
