import React from "react";
import "./Modal.css";

const Modal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;
  
    return (
      <div className="modal-overlay">
        <div className="modal-container">
          <h2 className="modal-title">Error</h2>
          <h3 className="modal-subtitle">Unable to Read Data</h3>
          <p className="modal-message">
            Our agent was unable to read data from the links you provided. Please resend the request with another URL or contact support.
          </p>
          <button className="modal-close-btn" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    );
  };
    

export default Modal;
