import React from "react";
import "../../../style/home.css";
import circle from "../../../assets/cricle.png";
import intoAiVideo from "../../../assets/ollehAi.mp4";

const Introduction = ({ scrollToSection }) => {
  console.log({ scrollToSection });
  return (
    <>
      <section className="olleh-intro">
        <div className="olleh-intro-wrapper section-spacing">
          <div className="olleh-intro-left">
            <div className="olleh-intro-sub-content">
              <button className="sub-btn">Hello!</button>
              <h2>We Think Opposite</h2>
            </div>

            <h1>
              Meet OLLEH.AI: Our cutting-edge AI agents handle calls with
              lightning speed, 24/7.
            </h1>
            <p className="olleh-chose-txet">
              <b>Why Choose OLLEH.AI?</b>
              <ul>
                <li>
                  <b>Cost-Efficient:</b> Cut costs by replacing human agents.
                </li>
                <li>
                  <b>Instant Support:</b> No hold times—our AI responds
                  instantly.
                </li>
                <li>
                  <b>Happy Customers:</b> Personalized interactions, anytime.
                </li>
              </ul>
              Ready to transform your call center?{" "}
              <button className="btn-primary" onClick={scrollToSection}>
                Try OLLEH.AI!{" "}
              </button>
            </p>
          </div>

          <div className="olleh-intro-right">
            <video
              src={intoAiVideo}
              autoPlay
              loop
              // muted
              controls
              playsInline
              controlsList="nodownload"
            ></video>
            <img src={circle} alt="circle" className="responsive-img" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Introduction;
