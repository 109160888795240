// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/footer-logo-bg.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.olleh-footer{
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    border-radius: 6rem 6rem 0 0;
}
.olleh-share-icons{
    display: flex;
    gap: 0.4rem;
    justify-content: center;
}
.olleh-footer-content{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1rem 0 2.5rem 0;
}
.privacy-policy{
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
    padding: 0 1rem;
    margin-right: 0.8rem;
}
.olleh-footer-content p, .olleh-footer-content a{
    font-size: 16px;
    color: #fff;
}

@media(max-width:767px){
    .olleh-footer{
        border-radius: 2rem 2rem 0 0;
    }
    .footer-logo{
        max-width: 190px;
    }
    .olleh-footer-content{
        flex-wrap: wrap;
    }
    .privacy-policy{
        border-right: 1px solid #fff;
        border-left: 0;
        padding: 0 1rem 0 0;
        margin-right: 0.8rem;
    }
    .olleh-footer-content{
        gap: 0.3rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/style/footer.css"],"names":[],"mappings":"AAAA;IACI,mDAA+C;IAC/C,2BAA2B;IAC3B,sBAAsB;IACtB,4BAA4B;IAC5B,kBAAkB;IAClB,4BAA4B;AAChC;AACA;IACI,aAAa;IACb,WAAW;IACX,uBAAuB;AAC3B;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;IACT,wBAAwB;AAC5B;AACA;IACI,4BAA4B;IAC5B,2BAA2B;IAC3B,eAAe;IACf,oBAAoB;AACxB;AACA;IACI,eAAe;IACf,WAAW;AACf;;AAEA;IACI;QACI,4BAA4B;IAChC;IACA;QACI,gBAAgB;IACpB;IACA;QACI,eAAe;IACnB;IACA;QACI,4BAA4B;QAC5B,cAAc;QACd,mBAAmB;QACnB,oBAAoB;IACxB;IACA;QACI,WAAW;IACf;AACJ","sourcesContent":[".olleh-footer{\n    background: url('../assets/footer-logo-bg.svg');\n    background-position: center;\n    background-size: cover;\n    background-repeat: no-repeat;\n    text-align: center;\n    border-radius: 6rem 6rem 0 0;\n}\n.olleh-share-icons{\n    display: flex;\n    gap: 0.4rem;\n    justify-content: center;\n}\n.olleh-footer-content{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 1rem;\n    padding: 1rem 0 2.5rem 0;\n}\n.privacy-policy{\n    border-right: 1px solid #fff;\n    border-left: 1px solid #fff;\n    padding: 0 1rem;\n    margin-right: 0.8rem;\n}\n.olleh-footer-content p, .olleh-footer-content a{\n    font-size: 16px;\n    color: #fff;\n}\n\n@media(max-width:767px){\n    .olleh-footer{\n        border-radius: 2rem 2rem 0 0;\n    }\n    .footer-logo{\n        max-width: 190px;\n    }\n    .olleh-footer-content{\n        flex-wrap: wrap;\n    }\n    .privacy-policy{\n        border-right: 1px solid #fff;\n        border-left: 0;\n        padding: 0 1rem 0 0;\n        margin-right: 0.8rem;\n    }\n    .olleh-footer-content{\n        gap: 0.3rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
